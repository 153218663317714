
import React from 'react'
import './Header.css';
import Header from './Header';
import Password from './Password';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function App() {
  const aosRefresh = () => {
    AOS.refresh()
  }

  React.useEffect(() => {
    setInterval(() => {
      AOS.init({ offset: 100, duration: 1000 });
    }, 1000)

    window.addEventListener('resize', aosRefresh)
    return () => {
      window.removeEventListener('resize', aosRefresh)
    }
  }, [])

  return (
    <Password>
      <Header />
    </Password>
  );
}

export default App;
