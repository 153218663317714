import logo from './logo.svg'

import Title from './Title'
import Modal from './Modal'
import { Scroll } from './Scroll';
import 'animate.css';

function Header() {
  return (
    <div className='App' id='top'>

      {/* header */}
      <header>

        <nav>


          <ul className='nav'>

            <li class="home">
              <a href="#top">

                <img className='home' src="/home.svg" />
                <img className='home' src="/home2.svg" />

              </a>
            </li>

            <li className='nav-list'>

              <a href="#works" className='nav-text'>
                Works
              </a>

            </li>

            <li className='nav-list'>

              <a href="#about" className='nav-text'>
                About
              </a>

            </li>

            <li className='nav-list'>

              <a href="#service" className='nav-text'>
                Service
              </a>

            </li>

            <li className='nav-list'>

              <a href="#contact" className='nav-text'>
                Contact
              </a>

            </li>

          </ul>
        </nav>

      </header>
      {/* /header */}


      {/* kv */}
      <div className='kv'>


        {/* <div className='kv-bg'>
        </div> */}

        <div className='kv-box'>

          <div className='kv-box-scrolldown'>
            <span>
              ScrollDown
            </span>
          </div>

        </div>

        <div className='kv-wrapper'>

          <h1 className='kv-text animate__animated animate__fadeInDown'>

            <span className='kv-text-gra'>
              Minato<br></br>Hashizume
            </span>


            <span className='kv-text-sub'>
              Web designer / director
            </span>

          </h1>

          <div className='kv-btn'>

            <a href="#about" class="btnarrow">About</a>

          </div>

        </div>
      </div>
      {/* /kv */}

      {/* modal */}
      <Modal id='trigger' title='Works'>
        <img src="/portfolio.png" />
        <div className='modal-text-contents'>
          <img src='/portfolio_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p style={color333}>
              ポートフォリオサイト
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p style={color333}>Web Design , Coding</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>



            <div className='modal-text-concept'>
              <p style={color333}>
                実績を掲載するためのポートフォリオサイトを制作しました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■カラー</div>
                自身が最も好むブルーをメインカラーとし、スッキリとした爽やかな印象を表現しました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■デザイン</div>
                ファーストビューでイラストを配置して、オリジナリティのあるデザインに仕上げています。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■ポイント
                </span>

                <br></br>
                ・実績の詳細をモーダル表示にして、各作品をすぐに閲覧できるようにしました。<br></br>
                ・全体的にアニメーションをつけ、躍動感のあるレイアウトにしています。<br></br>
                ・コンテンツを規律正しく配置し、適度な間隔を空けることで、視認性を高めました。<br></br>
                ・各コンテンツへ到達しやすくするため、スクロール時もヘッダーナビが追従するようにしています。<br></br>
                ・スマートフォンでも閲覧しやすいように最適化しました。
                {/* ・下部に設置したイラストは、横スクロールにして目に留まるようにしています */}
              </p>
            </div>

            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p style={color333}>Adobe XD , Adobe Photoshop , Visual Studio Code , Clip Studio Paint</p>

          </div>
        </div>
      </Modal>


      <Modal id='trigger2' title='Works'>
        <img src="/prima.png" />

        <div className='modal-text-contents'>
          <img src='/prima_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p style={color333}>
              Bar Prima｜公式サイト
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p style={color333}>Web Design</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>

            <div className='modal-text-concept'>
              <p style={color333}>
                神奈川県横浜市関内にあるBar Primaのサイトデザインを担当しました。 <br></br>
                ※デザイン制作進行中のため、ダミーテキストを配置しています。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■カラー</div>
                クライアントが希望していた木目調のデザインをベースに、
                ブラウンを基本とした諧調にまとめ、店内の暖かい雰囲気を表現しました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■デザイン</div>
                キービジュアルやコンテンツに印象的なロゴマークを配置して、インパクトを伝えます。
                「こだわらないことに、こだわる。」をキャッチフレーズに、
                Bar Primaのこだわりが伝わるデザインに仕上げました。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■ポイント
                </span>

                <br></br>
                ・ファーストビューでは店内の動画を大きく見せ、リアルな雰囲気を伝えています。<br></br>
                ・顧客目線で何を一番に確認したいかを考え、メニューをコンテンツの初めに配置しました。<br></br>
                ・コンテンツの全体の流れを、「どのような料理があるか（メニュー）」→「どこに店があるか（アクセス）」→「どのような予約手段があるか（コンタクト）」として、自然に予約方法へ誘導させる構成にしています。<br></br>
                ・ロゴマークの印象にテイストを揃えてデザインを制作しました。

              </p>
            </div>

            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p style={color333}>Adobe XD , Adobe Photoshop</p>

          </div>

        </div>
      </Modal>

      <Modal id='trigger3' title='Works'>
        <img src="/rakuten.png" />

        <div className='modal-text-contents'>
          <img src='/rakuten_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p style={color333}>
              楽天カード株式会社｜キャンペーンサイト
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>
            <p style={color333}>Web Design , Direction</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>


            <div className='modal-text-concept'>
              <p style={color333}>
                楽天カード社内に常駐し、毎月開催される複数のキャンペーンのデザイン・ディレクション業務を担当しました。 <br></br>
                ※制作物は一例です。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■課題・改善ポイント
                </span>
                <br></br>
                ×キャンペーン期間が決まっているため、制作素材の納品遅延などの理由があった場合も、スケジュールをずらせない状況がありました。
                <br></br>
                <span style={pStyle}>○期日内に納品できるようタスクを細分化して、徹底したスケジュール管理を行いました。足りない素材や未確定のコンテンツの作成は避け、進行できる箇所を進めることで常に先回りの対応を意識しました。</span>
                <br></br>
                <br></br>
                ×条件が複雑なキャンペーンが多く、ユーザーが条件達成まで辿り着けない懸念がありました。
                <br></br>
                <span style={pStyle}>○クライアントが作成した企画書を基に構成の改善を提案し、常に顧客目線のコンテンツ作成を心がけました。また、サービスを使用することで理解が深まると考え、実際にキャンペーンへエントリーして気になった点を次回の改善へと繋げていきました。</span>
                <br></br>
                <br></br>
                ×企業ロゴやカード券面を掲載する場合はレギュレーションが決まっており、必須文言を含めて、すべての要素をデザイン内に収める必要がありました。
                <br></br>
                <span style={pStyle}>○レギュレーション遵守を前提として、インセンティブ（総額1,100万ポイント還元！など）を一番目立たせることを最優先にしました。最低限必要な文言のみに精査して配置することで、コンテンツをすっきりとみせ、ユーザーが混乱しない構成になるよう気を付けました。</span>
              </p>

            </div>

            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p>Adobe Photoshop , Microsoft Excel</p>

          </div>

        </div>
      </Modal>

      <Modal id='trigger4' title='Works'>
        <img src="/card02.png" />

        <div className='modal-text-contents shadow-none'>
          <img src='/card02_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p>
              楽天カード株式会社｜キャンペーンバナー
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>
            <p>Web Design</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>

            <div className='modal-text-concept'>
              <p style={color333}>
                毎月開催される複数のキャンペーンでバナー制作を担当しました。
              </p>

              {/* <p style={color333}>
                <div style={titleStyle}>■カラー</div>
                

              </p> */}

              <p style={color333}>
                <div style={titleStyle}>■デザイン</div>
                キャンペーン内容、季節、提携先の企業ロゴに合わせたデザインなど、
                企画書をもとにクライアントが希望する構成を汲み取り、デザインに反映できるように注力しました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■ポイント</div>
                ・並行して複数のキャンペーンが開催されるため、類似したデザインにならないように、同じ季節のデザインでもあしらいを変更することで違う印象になるように心がけました。<br></br>
                ・企業ロゴやカード券面はレギュレーションが遵守できているか入念にチェックを行うことで、クライアント側のフィードバックの負担が軽減するように気を付けました。<br></br>
                ・同じデザインで10～20種類のサイズを制作する必要があるので、全サイズのデザインを統一できるよう、文言の配置・色使いに気を付けました。

                {/* ・様々な企業と提携したキャンペーンや、知名度の大きな期間限定キャンペーンまでその内容は多岐に渡るため、キャンペーンに伴うお得な特典をいかに伝えられるか常に考え表現しました。 */}
              </p>
            </div>




            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p>Adobe Photoshop , Microsoft Excel</p>

          </div>

        </div>
      </Modal>

      <Modal id='trigger5' title='Works'>
        <img src="/1day.png" />

        <div className='modal-text-contents'>
          <img src='/1day_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p>
              1DAYメイド・プロ｜サービス紹介サイト<br></br>
              <a href='https://lp.insag.jp/' target='_blank' style={fontB}>LP.INSAG.JP</a>
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p>Web Direction</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>

            <div className='modal-text-concept'>
              <p>
                保険代理店向けホームページ作成サービスの新規構築・運用を担当。
                ランディングページ・顧客管理システム・Webサイト作成用管理画面（フロント含）の構築を一環して対応しました。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■課題・改善ポイント
                </span>
                <br></br>
                ×Webサイト作成は敷居もコストも高いと感じている保険代理店が多く、自社サイトを持っていない企業が多く見受けられました。
                <br></br>
                <span style={pStyle}>
                  ○Webサイト作成に対する敷居を低くするため、「低コスト」で「カンタン」にホームページ作成を実現できるサービスの開発を行いました。
                  申し込み後は専用の管理画面から掲載内容を入力するだけで、専門知識がなくても自社ですぐにWebサイトを作成することができます。
                  また、ご契約いただいたユーザーへ、管理画面で入力が難しいと感じた内容をヒアリングし、意見の多い項目から改善を重ねていきました。</span>
                <br></br>
                <br></br>
                ×保険代理店のWebサイトは、公開前に必ず取引保険会社への申請が必要になります。そこで、公開前の申請方法を円滑に行える手段を検討する必要がありました。
                <br></br>
                <span style={pStyle}>
                  ○専用の管理画面から設定できる公開ステータスに、パスワード付き公開を追加しました。また、保険会社の確認が完了した場合、承認番号が付与されるため、管理画面から管理できるように入力項目を設置しました。
                  ユーザーの任意のタイミングで保険会社への申請ができ、スムーズな公開に繋げることができました。
                </span>
                <br></br>
                <br></br>
                ×ユーザーごとのアカウント情報や契約情報の管理が煩雑になる懸念がありました。
                <br></br>
                <span style={pStyle}>
                  ○ヒューマンエラーによるミスを防ぐため、お申込みからアカウント発行まで、完全自動のシステムを構築しました。お申込みからすぐにアカウントが発行されるため、ユーザーにスピーディなサービス提供が可能になりました。</span>
              </p>


              <p>
                <div style={titleStyle}>■メディア</div>

                保険毎日新聞にサービス開発ディレクターとして掲載されました。
                <a href='/media.pdf' target='_blank'><img src='/media.png' style={shinbunStyle} /></a>
              </p>
            </div>

            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p>Backlog , Brushup , Cacoo , Googleスプレッドシート</p>

          </div>

        </div>
      </Modal>

      <Modal id='trigger6' title='Works'>
        <img src="/1day_site.png" />

        <div className='modal-text-contents'>
          <img src='/1day_site_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p>
              1DAYメイド・プロ｜テンプレートサイト<br></br>
              <a href='https://sample.insag.jp/' target='_blank' style={fontB}>SAMPLE.INSAG.JP</a>
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p>Web Direction</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>

            <div className='modal-text-concept'>
              <p>
                保険代理店向けホームページ作成サービスの新規構築・運用を担当。
                専用の管理画面から作成できるテンプレートサイトの構築を行いました。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■課題・改善ポイント
                </span>
                <br></br>
                ×専門知識のないユーザーが、「カンタン」にWebサイトを制作できる入力画面を検討する必要がありました。
                <br></br>
                <span style={pStyle}>
                  ○別会社が提供しているWebサイト作成サービスや、シェア率の高いWebサービスのUI・UXを調査して、自社でオリジナルのCMSを作成しました。
                  保険代理店向けの入力項目に最適化することで、専門知識の低いユーザーでも「カンタン」にWebサイト作成～公開までの実現が可能になりました。
                </span>
                <br></br>
                <br></br>
                ×テンプレートサービスになるため、類似したデザインになる懸念がありました。
                <br></br>
                <span style={pStyle}>
                  ○12色からデザインカラーを変更できるようにしました。キービジュアル画像やロゴ画像なども設定を変更でき、ユーザー間でWebサイトデザインの差別化が図れるようにしました。
                  また、選択できるデザインパターンの追加も現在対応中になります。
                </span>
                <br></br>
                <br></br>
                ×設定方法がわからず公開できないなどの理由で、未公開のまま放置してしまうユーザーが発生すると感じました。
                <br></br>
                <span style={pStyle}>
                  ○自社で公開状況を毎月確認し、公開まで時間がかかっているユーザーへサポート連絡を行うよう徹底しました。
                  また、ユーザー向けの操作マニュアルも作成し、専用の管理画面からすぐに確認できるようにしています。
                  ユーザーから質問の多い入力項目は、改修やマニュアル修正を行い、常にブラッシュアップを重ねています。

                </span>
              </p>


            </div>

            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p>Backlog , Brushup , Cacoo , Googleスプレッドシート</p>

          </div>

        </div>
      </Modal>

      <Modal id='trigger7' title='Works'>
        <img src="/siaa.png" />

        <div className='modal-text-contents'>
          <img src='/siaa_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p>
              一般社団法人公的保険アドバイザー協会｜紹介サイト<br></br>
              <a href='https://siaa.or.jp/' target='_blank' style={fontB}>SIAA.OR.JP</a>
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p>Web Direction</p>

            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>



            <div className='modal-text-concept'>
              <p>
                認定試験の合格者のみ会員になれる制度に、認定試験をスキップして会員になれる「聴講会員」制度を導入しました。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■課題・改善ポイント
                </span>

                <br></br>
                ×会員登録できるのは認定資格取得者のみという制限があり、資格を取得せず会員になりたいターゲット層をカバーできていませんでした。
                <br></br>
                <span style={pStyle}>○既存の会員制度「認定会員」に加えて、資格を取得せず会員登録ができる「聴講会員」制度を追加しました。</span>
                <br></br><br></br>
                ×既存コンテンツ（紹介サイト・会員専用サイト）に新規会員制度を追加する場合、各コンテンツの導線が複雑になってしまう懸念がありました。<br></br>
                <span style={pStyle}>○「認定会員」と「聴講会員」の制度の違いにユーザーが混乱しないよう、サイト全体の階層から見直しを行いました。
                  会員専用サイトでも、専用コンテンツの差分が発生するため、ログインした会員にあわせてコンテンツの表示を切り替え、ストレスのない設計を実現できました。
                </span><br></br><br></br>
                ×新規会員制度追加に伴い、顧客管理が煩雑にならないよう顧客管理システムを最適化する必要がありました。<br></br>
                <span style={pStyle}>○申し込みから会員アカウント発行までの導線を見直し、「認定会員」と「聴講会員」でラベル分けされるよう、顧客管理システムを改修しました。申し込みがあった時点でそれぞれの会員区分に自動で振り分けられるようになり、スムーズな運用につながりました。</span>
              </p>
            </div>



            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p>Backlog , Brushup , Cacoo , Googleスプレッドシート</p>


          </div>

        </div>
      </Modal >

      <Modal id='trigger8' title='Works'>
        <img src="/customerlinks.png" />

        <div className='modal-text-contents'>
          <img src='/customerlinks_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p style={color333}>
              株式会社カスタマーリンクス｜コーポレートサイト
              <a href='https://customerlinks.jp/' target='_blank' style={fontB}>CUSTOMERLINKS.JP</a>
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p style={color333}>Web Direction</p>


            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>


            <div className='modal-text-concept'>
              <p style={color333}>
                コーポレートサイト制作のディレクションを担当しました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■カラー</div>
                ホワイトをベースに、全体をメインカラーのブルーを基本とした階調にまとめました。
                清潔感による安心感と、清々しく誠実な印象から、信頼感が伝わるように心がけました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■デザイン</div>
                「お客様と一生涯の絆を」をキャッチフレーズに、
                長きに渡って頼れる信頼感と安心感が伝わるデザインに仕上げました。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■ポイント
                </span>

                <br></br>
                ・ファーストビューで印象的な画像を大きく見せ、全体的にスッキリとシンプルな要素による構成は、スタイリッシュな雰囲気を与えます。<br></br>
                ・ファーストビューでは、クライアントの事業を通して「世代をまたいだ長い月日を寄り添い、子供が大人になっても、親がシニアになっても、変わらない笑顔（幸せ）を届ける」というメッセージを込めたスライドショーを展開します。<br></br>
                ・写真やリンク部分のブロックをただ並べるのではなく、上下に少しズラして配置することで、レイアウトに躍動感を与えています。<br></br>
                ・各要素を大きく規律正しく配置し、しっかり間隔を空けることで、コンテンツの視認性と利便性を高めています。

              </p>
            </div>


            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p style={color333}>Backlog , Brushup , Cacoo , Googleスプレッドシート</p>


          </div>

        </div>
      </Modal>

      <Modal id='trigger9' title='Works'>
        <img src="/irohani.png" />

        <div className='modal-text-contents'>
          <img src='/irohani_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p style={color333}>
              いろはにほけん｜コーポレートサイト<br></br>
              <a href='https://1682hoken.com/' target='_blank' style={fontB}>1682HOKEN.COM</a>
            </p>


            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p style={color333}>Web Direction</p>


            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>


            <div className='modal-text-concept'>
              <p style={color333}>
                コーポレートサイト制作のディレクションを担当しました。
              </p>




              <p style={color333}>
                <div style={titleStyle}>■カラー</div>
                コーポレートカラーでもあるレッドの割合いを高めに取り入れ、
                全体的にカラフルかつポップな印象にしています。クライアントの活力（元気・全力）を表現しました。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■デザイン</div>
                クライアントの仕事に対する全力・一生懸命なイメージや、
                高いチームワークとスタッフの個性を伝えられるデザインにしました。
                また、真似できないような強い個性と強烈なインパクトを表現するために、マンガ（コミック）をモチーフとして表現しています。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■ポイント
                </span>

                <br></br>
                ・各所にマンガ要素（コマ割り、吹き出し、ポップなパーツなど）を散りばめ、元気で楽しい雰囲気を演出しています。<br></br>
                ・ヘッダーの各ページリンクを吹き出しデザインとし、並びをバラつかせることで個性の強いナビゲーションにしています。<br></br>
                ・ファーストビューから全力で、コミカルかつユーモアのある表現を使い、強烈なインパクトを与えます。<br></br>
                ・人物の写真も多数盛り込み、スタッフの表情や雰囲気を伝えています。

              </p>

            </div>


            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p style={color333}>Backlog , Brushup , Cacoo , Googleスプレッドシート</p>

          </div>

        </div>
      </Modal>

      <Modal id='trigger10' title='Works'>
        <img src="/nankai.png" />

        <div className='modal-text-contents'>
          <img src='/nankai_page.png' />

          <div className='modal-text'>
            <p className='modal-text-title ttl1-line'>
              Title
            </p>

            <p>南海保険サービス株式会社｜コーポレートサイト<br></br>
              <a href='https://nankaihoken.co.jp/' target='_blank' style={fontB}>NANKAIHOKEN.CO.JP</a>
            </p>

            <p className='modal-text-title ttl1-line dummy'>
              Role
            </p>

            <p style={color333}>Web Direction</p>


            <p className='modal-text-title ttl1-line dummy'>
              Concept
            </p>


            <div className='modal-text-concept'>
              <p style={color333}>
                コーポレートサイト制作のディレクションを担当しました。
              </p>




              <p style={color333}>
                <div style={titleStyle}>■カラー</div>
                ホワイトをベースに、ロゴマークで使われているオレンジをメインカラー、レッドをアクセントカラーとしました。
                コーポレートカラーを活かしつつ、スッキリと清々しい印象が伝わるようにまとめています。
              </p>

              <p style={color333}>
                <div style={titleStyle}>■デザイン</div>
                「グループ従業員・OBの方々」が見やすく・使いやすいことを前提に、
                親しみやすく優しいデザインをコンセプトに作成しました。
              </p>

              <p style={color333}>
                <span style={titleStyle}>
                  ■ポイント
                </span>

                <br></br>
                ・キービジュアルには、笑顔の印象が良い面談風景イメージに「お客さまとともに」ロゴと「保険のトータルアドバイザー」というキャッチコピーを添えることで信頼感を伝えます。<br></br>
                ・キービジュアルの背景にある町並みは、暮らしを広く支えているというイメージであり、淡い水彩画タッチのイラストにすることで柔らかさ・優しさを印象づけています。<br></br>
                ・各要素の角に丸みを付けることで、全体を通して柔らかさ・優しさの印象を保ちます。<br></br>
                ・柔らかいタッチのアイコンやイラストを添えることで、親しみやすさを持たせます。

              </p>

            </div>

            <p className='modal-text-title ttl1-line dummy'>
              Tool
            </p>

            <p style={color333}>Backlog , Brushup , Cacoo , Googleスプレッドシート</p>

          </div>

        </div>
      </Modal>

      {/* /modal */}


      {/* contents */}
      <div className='contents' id='works'>


        <div data-aos="fade-up">
          <Title title='Works' subtitle='実績' />

          <div className='contents-line'>
          </div>
        </div>

      </div>
      {/* /contents */}

      {/* works */}
      <div className='works'>

        <div className="works-contents">

          <ul className="works-contents-list">

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger" className="trigger">

                <img src="/portfolio.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">Portfolio Site</h2>

                  <p className="works-category">Web Design , Coding</p>

                </div>

              </label>



            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger2" className="trigger">

                <img src="/prima.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">Bar Prima</h2>

                  <p className="works-category">Web Design</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger3" className="trigger">

                <img src="/rakuten.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">楽天カード株式会社｜キャンペーンサイト</h2>

                  <p className="works-category">Web Design , Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger4" className="trigger">

                <img src="/card02.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">楽天カード株式会社｜キャンペーンバナー</h2>

                  <p className="works-category">Web Design , Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger5" className="trigger">

                <img src="/1day.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">1DAYメイド・プロ｜紹介サイト</h2>

                  <p className="works-category">Web Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger6" className="trigger">

                <img src="/1day_site.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">1DAYメイド・プロ｜テンプレートサイト</h2>

                  <p className="works-category">Web Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger7" className="trigger">

                <img src="/siaa.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">公的保険アドバイザー協会｜紹介サイト</h2>

                  <p className="works-category">Web Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger8" className="trigger">

                <img src="/customerlinks.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">株式会社カスタマーリンクス｜コーポレートサイト</h2>

                  <p className="works-category">Web Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger9" className="trigger">

                <img src="/irohani.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">いろはにほけん株式会社｜コーポレートサイト</h2>

                  <p className="works-category">Web Direction</p>

                </div>

              </label>

            </li>

            <li className="works-contents-listitem" data-aos="fade-up">

              <label htmlFor="trigger10" className="trigger">

                <img src="/nankai.png" className='neumorphism' />

                <div className="works-contents-listitem-textblock">

                  <h2 className="works-title">南海保険サービス株式会社｜コーポレートサイト</h2>

                  <p className="works-category">Web Direction</p>

                </div>

              </label>

            </li>

          </ul>


        </div>


      </div>
      {/* /works */}


      {/* contents */}
      <div className='contents' id='about'>

        <div data-aos="fade-up">
          <Title title='About' subtitle='ハシヅメ ミナト' />

          <div className='contents-line'>
          </div>
        </div>

      </div>
      {/* /contents */}

      {/* about */}
      <div className='about'>


        <p className='about-profile' data-aos="fade-up">
          こんにちは、橋詰 湊（ハシヅメ ミナト）と申します。
        </p>

        <div className='about-profile-img' data-aos="fade-up">
          <img src='/minato.png'></img>
        </div>

        <p className='sub-profile' data-aos="fade-up" data-aos-offset="200" >
          当サイトをご覧いただき、誠にありがとうございます。私は高知県出身の、青色をこよなく愛するWebクリエイターです。<br></br><br></br>
          2017年5月にWeb制作会社へ入社後、広告バナーやLPデザイン、イラスト制作を中心に、制作チームのデザイナー業務を担当。<br></br>
          2018年10月からは、ディレクターとしてクライアント先へ常駐し、2年間キャンペーンサイトのディレクション業務に従事しました。<br></br>
          その後、別のWeb制作会社へ転職し、ディレクターとして自社プロダクト開発・運用、Webサイト制作・リニューアルなど、幅広く業務を経験してきました。<br></br><br></br>
          今後はデザインを主軸として、培ってきたディレクター経験を活かし、より顧客本位の制作を行っていきたいと考えています。
        </p>


        <p class="modal-text-title ttl1-line" style={toolSkillStyle} data-aos="fade-up">Tool Skills</p>
        <div className='tool' data-aos="fade-up">
          <div className='' >
            <img src='/xd.png'></img>
            <p>Adobe XD</p>
          </div>
          <div className='' >
            <img src='/ps.png'></img>
            <p>Adobe Photoshop</p>
          </div>
          <div className='' >
            <img src='/ai.png'></img>
            <p>Adobe Illustrator</p>
          </div>
          <div className='' >
            <img src='/vs.png'></img>
            <p>Visual Studio Code</p>
          </div>

        </div>

      </div>
      {/* /about */}

      {/* contents */}
      <div className='contents' id='service'>

        <div data-aos="fade-up">
          <Title title='Service' subtitle='お手伝いできること' />

          <div className='contents-line'>
          </div>
        </div>

      </div>
      {/* /contents */}

      {/* service */}
      <div className='service'>

        <div className="service-img" data-aos="fade-up">
          <h2 className="service-img-title">Web Direction<br></br>ディレクション</h2>
          <img src='/note.png'></img>
          <p className="service-img-txt">デザイン制作前の事前準備は特に重要です。まずはお客さまの要望をじっくりとヒアリングし、現状の問題点を洗い出します。そこから必要なコンテンツを判断し、お客さまにとって最適なWebサイト制作の提案を行います。</p>
        </div>

        <div className="service-img" data-aos="fade-up">

          <h2 className="service-img-title">Web Design<br></br>デザイン</h2>

          <img src='/pc.png'></img>

          <p className="service-img-txt">ヒアリングに基づいたデザインを作成します。お客さまの希望・目的に沿ったデザインになるよう、常に顧客本位のデザイン制作を心がけています。使い勝手がよく、魅力が伝わるようなデザインの制作に尽力します。</p>

        </div>

        <div className="service-img" data-aos="fade-up">
          <h2 className="service-img-title">Illustration<br></br>イラスト</h2>
          <img src='/pen.png'></img>
          <p className="service-img-txt">SNSアイコンやウェルカムボードなど、ご希望のテイストで似顔絵・版権キャラクターなどのイラストを作成いたします。お客さまの希望とする色見・雰囲気などをヒアリングし、ご納得いただけるイラストになるまで調整を行います。</p>
        </div>

      </div>
      {/* /service */}

      {/* <div className="d-demo">

        <div className="d-demo__wrap">

          <ul className="d-demo__list d-demo__list--left">

            <li className="d-demo__item"><img src='/minato2.jpg' alt></img></li>
            <li className="d-demo__item"><img src='/aqua.png' alt></img></li>
            <li className="d-demo__item"><img src='/peko.jpg' alt></img></li>
            <li className="d-demo__item"><img src='/cika.png' alt></img></li>
            <li className="d-demo__item"><img src='/poke.png' alt></img></li>
            <li className="d-demo__item"><img src='/perusona.jpg' alt></img></li>

          </ul>

          <ul className="d-demo__list d-demo__list--left">

            <li className="d-demo__item"><img src='/minato2.jpg' alt></img></li>
            <li className="d-demo__item"><img src='/aqua.png' alt></img></li>
            <li className="d-demo__item"><img src='/peko.jpg' alt></img></li>
            <li className="d-demo__item"><img src='/cika.png' alt></img></li>
            <li className="d-demo__item"><img src='/poke.png' alt></img></li>
            <li className="d-demo__item"><img src='/perusona.jpg' alt></img></li>

          </ul>

        </div>

      </div> */}


      {/* contact */}
      <div className='contact' id='contact'>

        <div data-aos="fade-up">

          <Title title='Contact' subtitle='お問い合わせ' />

          <div className='contents-line'></div>

          <p className='contact-text'>お問い合わせやご依頼はこちらから</p>

          <div className='contact-btn'>

            <a href="mailto:h371o.214&#64;gmail.com" class="contact-btnarrow">Contact</a>

          </div>
        </div>

      </div>
      {/* /contact */}


      {/* footer */}
      <footer>

        <p className='copyright'>©︎ 2022 Minato Hashizume / 3710.</p>

        <a href="#top">
          <Scroll />
          <img className='arrow' src="/arrow.svg" />
        </a>

      </footer>
      {/* /footer */}

      <nav>

        <div>
          <ul className='nav nav-sp'>

            <li class="home">
              <a href="#top">

                <img className='home' src="/home.svg" />
                <img className='home' src="/home2.svg" />

              </a>
            </li>

            <li className='nav-list'>

              <a href="#works" className='nav-text'>
                Works
              </a>

            </li>

            <li className='nav-list'>

              <a href="#about" className='nav-text'>
                About
              </a>

            </li>

            <li className='nav-list'>

              <a href="#service" className='nav-text'>
                Service
              </a>

            </li>

            <li className='nav-list'>

              <a href="#contact" className='nav-text'>
                Contact
              </a>

            </li>

          </ul>
        </div>
      </nav>


    </div >
  )
}

export default Header


const color333 = { color: '#333' }
const titleStyle = { fontWeight: 'bold', color: '#013884', fontSize: 18 }
const pStyle = { fontWeight: 'bold', color: '#013884' }
const fontB = { fontWeight: 'bold' }

const shinbunStyle = { display: 'flex', boxShadow: 'none', width: '50%' }

const toolSkillStyle = { margin: '0 auto', maxWidth: '1200px', paddingTop: '100px' }
