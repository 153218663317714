
import './Modal.css';

function Modal({ id, title, children }) {
  // jsいれる
  return (
    <div className="modal_wrap">

      <input id={id} type="checkbox" onChange={(e) => {

          e.target.disabled = true
          const transitionEl = document.getElementById(`${id}-overlay`);
          transitionEl.addEventListener('transitionend', () => e.target.disabled = false, { once: true });

        if (e.target.checked) {
          document.querySelector('html').style.overflow = 'hidden'
          document.getElementById(`${id}-content`).scrollTo(0, 0);
        } else {
          document.querySelector('html').style.overflow = ''
        }
      }}></input>

      <div id={`${id}-overlay`} className="modal_overlay">

        <label htmlFor={id} className="modal_trigger"></label>

        <div className='oya'>
          <div className='close-box'>
            <label htmlFor={id} className="close_button">✖️</label>
          </div>

          <div id={`${id}-content`} className="modal_content">
            <div className='bg'>
              <h1 className='modal_title2'>{title}</h1>
            </div>
            <div className='flex'>
              {children}
            </div>
          </div>
        </div>

      </div>


    </div>
  )
}

export default Modal
