import React from 'react'

function Password({ children }) {
  const [passwd, setPasswd] = React.useState('')
  const setPass = (e) => {
    e.preventDefault()
    setPasswd(document.getElementById('password')?.value)
  }
  return (
    <>
      {passwd === process.env.REACT_APP_PASSWORD ? (
        <>{children}</>
      ) : (
        <div style={wrapper}>
          <div style={container}>
            <p style={description}>パスワードを入力してください</p>
            <form onSubmit={setPass}>
              <input
                type='password'
                id='password'
                name='password'
                minLength={8}
                required
                autoComplete='on'
                style={input}
              ></input>
              <input type='submit' value='確定' style={button}></input>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default Password


const wrapper = { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100vw', height: '80vh' }
const container = { maxWidth: 340 }
const button = { margin: '0 0 0 10px', padding: '8px 24px', fontSize: 16, height: 47, verticalAlign: 'bottom' }
const input = { padding: '8px 16px', margin: 0, fontSize: 24, width: '60%' }
const description = { marginBottom: 8, fontSize: 16, } 