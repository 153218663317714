export default function Title(props) {

    // javascriptかける

    return (
        <h1>
            {props.title}
            <span className='subtitle'>{props.subtitle}</span>
        </h1>
    )
}