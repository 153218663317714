import React from 'react';

/**
 * javascript 関数の話
 * function 名前 (引数) {
 *
 * return;
 *
 * }
 *
 * 関数 = コンポーネント
 */
// function nankanoshori(hikisuu) {
//   return 1 + hikisuu
// }
// nankanoshori(7);
// component (部品)
// props (小道具)
// component に props を渡す

export function Scroll () {
    
    React.useEffect(() => {
        const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');

        for (let i = 0; i < smoothScrollTrigger.length; i++) {
            smoothScrollTrigger[i].addEventListener('click', (e) => {
                e.preventDefault();
                let href = smoothScrollTrigger[i].getAttribute('href');
                let targetElement = document.getElementById(href.replace('#', ''));
                const rect = targetElement.getBoundingClientRect().top;
                const offset = window.pageYOffset;
                const gap = window.matchMedia('(max-width:767px)').matches ? 0 : 100;
                const target = rect + offset - gap;
                window.scrollTo({
                    top: target,
                    behavior: 'smooth',
                });
            });
        }
    }, []);
    
    return null;
}
